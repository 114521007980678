import { PrintPdf } from "components/common/printPdf";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGeneratePdfPayslipMutation } from "store/api/payrollApi";
import styled from "styled-components";
import { formatRupiah } from "utils/numberUtils";

const PayrollPayslipDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const Table = styled.table`
    tr th {
      padding: 10px;
    }
    tr td {
      padding: 10px;
    }
  `;
  const { payslipDetail } = useSelector((state) => state?.payrollSlice);
  const text = `THIS IS A COMPUTER GENERATED PRINTOUT AND NO SIGNATURE IS REQUIRED

PLEASE NOTE THAT THE CONTENTS OF THIS STATEMENT SHOULD BE TREATED WITH ABSOLUTE CONFIDENTIALITY EXCEPT TO THE EXTENT YOU ARE REQUIRED TO MAKE DISCLOSURE FOR ANY TAX, LEGAL, OR REGULATORY PURPOSES. ANY BREACH OF THIS CONFIDENTIALITY OBLIGATION WILL BE DEALT WITH SERIOUSLY, WHICH MAY INVOLVE DISCIPLINARY ACTION BEING TAKEN.

This payslip is generated by Timhut
https://hr.timhut.studio`;
  const data = useMemo(() => {
    return payslipDetail?.payload?.data?.find((d) => d?._id === id);
  }, [payslipDetail?.payload, id]);
  const detail = payslipDetail?.payload;
  useEffect(() => {
    if (!data) {
      navigate("/employee-payslip");
    }
  }, [data]);
  const basicSalary = data?.user?.userInformation?.paymentDetail?.basicSalary;
  const totalEarning = data?.claimsReimbursements?.data?.reduce((pre, v) => {
    pre += v?.price;
    return pre;
  }, 0);
  const approvedEarning = data?.approvedEarning?.data?.reduce((pre, v) => {
    pre += v?.price;
    return pre;
  }, 0);
  const identificationDocument = useMemo(() => {
    return data?.user?.userInformation?.identificationDocument?.identityNumber;
  }, [data]);
  const userInformation = useMemo(() => {
    return data?.user?.userInformation;
  }, [data]);
  const user = useMemo(() => {
    return data?.user;
  }, [data]);
  const ComTableBodyEarnings = useCallback(() => {
    return (
      <>
        <tr className="border-b  border-x border-slate-200 capitalize">
          <td>basic salary</td>
          <td>{formatRupiah(basicSalary)}</td>
        </tr>
        {data?.approvedEarning?.data?.map((d, i) => {
          return (
            <tr
              key={i + "-claimsReimbursements"}
              className=" capitalize border-x border-slate-200"
            >
              <td>{d?.label}</td>
              <td>{formatRupiah(d?.price)}</td>
            </tr>
          );
        })}
        <tr className=" capitalize font-bold  border-x border-slate-200">
          <td>claims Reimbursements</td>
        </tr>
        {data?.claimsReimbursements?.data?.map((d, i) => {
          return (
            <tr
              key={i + "-claimsReimbursements"}
              className=" capitalize border-x border-slate-200"
            >
              <td>{d?.label}</td>
              <td>{formatRupiah(d?.price)}</td>
            </tr>
          );
        })}
      </>
    );
  }, [data]);
  const ComTableBodyDeductions = useCallback(() => {
    return (
      <>
        {data?.deductions?.data?.map((d, i) => {
          return d?.status === "total-deductions" ? (
            <tr
              key={i + "-claimsReimbursements"}
              className=" capitalize bg-gray-300 font-bold px-10"
            >
              <td colSpan={2}>{d?.label}</td>
              <td>{formatRupiah(d?.price)}</td>
            </tr>
          ) : (
            <tr
              key={i + "-claimsReimbursements"}
              className=" capitalize border border-slate-200"
            >
              <td>{d?.label}</td>
              <td>{formatRupiah(d?.price)}</td>
            </tr>
          );
        })}
      </>
    );
  }, [data]);
  const [generatePdf] = useGeneratePdfPayslipMutation();

  const handleDownload = async () => {
    try {
      // Mengirim permintaan untuk menghasilkan PDF
      await generatePdf("");
    } catch (err) {
      console.error("Download error:", err);
    }
  };
  const totalNetsPay = useMemo(() => {
    const deductionTotal = data?.deductions?.data?.reduce((pre, v) => {
      pre += v?.price;
      return pre;
    }, 0);

    return totalEarning + approvedEarning + basicSalary - deductionTotal;
  }, [totalEarning, data, basicSalary, approvedEarning]);
  return (
    <div className="py-5">
      <PrintPdf downloadCallback={handleDownload} asd={"ssd"}>
        <div className="flex items-center justify-between">
          <img
            src="/images/favicon-timhut.png"
            className="object-contain w-[100px]  "
            alt=""
          />
          <div className="text-red-500">CONFIDENTIALS</div>
        </div>
        <div className="flex items-center justify-between font-semibold capitalize mt-4">
          <p className="text-xl">timhut</p>
          <div>PAYSLIP</div>
        </div>
        <div className="w-[300px]">
          150 Orchard Rd, #08-19, Singapore Phone Number : +62 21291232
        </div>
        <div className="mt-10 ">
          <div className="flex justify-between gap-4">
            <div className="grid grid-cols-2 gap-x-4">
              <div className="capitalize">payroll period</div>
              <div>
                : {dayjs(detail?.startDate).format("D MMMM")} -{" "}
                {dayjs(detail?.endDate).format("D MMMM YYYY")}
              </div>
              <div className="capitalize">employee ID</div>
              <div>: {identificationDocument}</div>
              <div className="capitalize">employee name</div>
              <div>
                : {user?.firstName} {user?.lastName}
              </div>
              <div className="capitalize">email</div>
              <div>: {user?.email}</div>
              <div className="capitalize">total employee</div>
              <div>: {detail?.data?.length} employees</div>
            </div>
            <div className="grid grid-cols-2 gap-x-4">
              <div className="capitalize">location</div>
              <div>: {detail?.location?.name}</div>
              <div className="capitalize">dept / job Position</div>
              <div>
                : {userInformation?.employeementDetail?.departments}/
                {userInformation?.employeementDetail?.jobPosition}
              </div>
              <div className="capitalize">employee status</div>
              <div>-</div>
              <div className="capitalize">phone number</div>
              <div>{user?.phoneNumber}</div>
            </div>
          </div>

          <Table className=" w-full mt-10 text-left">
            <thead>
              <tr className="bg-gray-300 capitalize">
                <th className="w-full">earnings</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ComTableBodyEarnings />
            </tbody>
            <tfoot>
              <tr className="bg-gray-300 capitalize px-10">
                <th colSpan={2}>Total earnings</th>
                <th>
                  {formatRupiah(basicSalary + totalEarning + approvedEarning)}
                </th>
              </tr>
            </tfoot>
          </Table>
          <Table className=" w-full mt-10 text-left">
            <thead>
              <tr className="bg-gray-300 capitalize">
                <th className="w-full">deductions</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ComTableBodyDeductions />
            </tbody>
          </Table>
          <div className=" w-full mt-10 text-left grid grid-cols-[1fr_auto_auto] gap-4 bg-gray-300 p-3">
            <div className="capitalize col-start-2 font-bold">
              Total net pay
            </div>
            <div className="capitalize col-start-3 font-bold">
              {formatRupiah(totalNetsPay)}
            </div>
          </div>
        </div>

        <div className="text-gray-400 mt-[100px] whitespace-pre-line">
          {text}
        </div>
      </PrintPdf>
    </div>
  );
};
export default PayrollPayslipDetail;
