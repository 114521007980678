import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login-slice",
  initialState: {
    isLogin: false,
    currentUser: null,
    currentRole: "",
    token: "",
  },
  reducers: {
    setLoginReducer: (state, action) => {
      state.isLogin = true;
      state.currentUser = action.payload.currentUser;
      state.token = action.payload.token;
      if (state.currentRole === "") {
        state.currentRole = action.payload.currentUser?.role[0];
      }
    },
    setLogoutReducer: (state) => {
      state.currentUser = null;
      state.currentRole = "";
      state.token = "";
      localStorage.removeItem("role");
    },
    setRoleReducer: (state) => {
      if (state.currentRole === "Admin") {
        state.currentRole = state.currentUser.role.includes("Supervisor")
          ? "Supervisor"
          : "Employee";
      } else {
        state.currentRole = "Admin";
      }
    },
  },
});

export const { setLoginReducer, setLogoutReducer, setRoleReducer } =
  loginSlice.actions;
export default loginSlice.reducer;
