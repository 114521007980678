import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Accordion,
  InputText,
  InputDate,
  ToggleSwitch,
  CheckBox,
} from "@bluesilodev/timhutcomponents";
import SaveIconSVG from "assets/icons/SaveIconSVG/SaveIconSVG";

import { useFormik } from "formik";
import { CpfbSchema } from "type/typeCpf";
import { useSelector } from "react-redux";
import { useAddCpfbMutation, useUpdateCpfbMutation } from "store/api/cpfbApi";
import { useAlertApi } from "services/alert-api";
import { ArrowRightSVG } from "assets/icons/ArrowRightSVG";
import { EqualSVG } from "assets/icons/EqualSVG";
import TrashSvg from "assets/icons/TrashSvg/TrashSvg";
import UserAddIcon from "assets/icons/UserAddSVG/UserAdd";
import { cleanNumberInput } from "utils/numberUtils";
import { parse } from "date-fns";
import dayjs from "dayjs";

const AddEditCpfb = () => {
  const params = useParams();
  /**
   * @typedef {Object} CpfValidationSchema
   * @property {import("type/typeCpf").CpfValidationSchema} data
   */
  /**
   * @type {CpfValidationSchema}
   */
  const cpfb = useSelector((state) => state.cpfbSlice);
  const [requestCpfb, resultCpfb] = useAddCpfbMutation();
  const [requestUpdateCpfb, resultUpdateCpfb] = useUpdateCpfbMutation();

  const navigate = useNavigate();

  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    resetForm,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues: {
      cpfName: "",
      cpfWages: "",
      cpfStartDate: "",
      cpfEndDate: "",
      cpfStatus: false,
      cpfMaxSelect: false,
      cpfList: [
        {
          employeesAgeFrom: 0,
          employeesAgeTo: 20,
          employerContribution: 0,
          employeeContribution: 0,
          totalContribution: 0,
        },
        {
          employeesAgeFrom: 21,
          employeesAgeTo: 30,
          employerContribution: 0,
          employeeContribution: 0,
          totalContribution: 0,
        },
      ],
    },
    validationSchema: CpfbSchema,
    onSubmit: async (values) => {
      try {
        const parseValues = { ...values };
        if (
          parseValues?.__v !== "" &&
          parseValues?.__v !== undefined &&
          params?.id
        ) {
          delete parseValues.__v;
        }
        if (parseValues?.createdAt && params?.id) {
          delete parseValues.createdAt;
        }
        if (parseValues?.updatedAt && params?.id) {
          delete parseValues.updatedAt;
        }
        parseValues.cpfStartDate = parse(
          parseValues?.cpfStartDate,
          "dd/MM/yyyy",
          new Date(),
        );
        parseValues.cpfEndDate = parse(
          parseValues?.cpfEndDate,
          "dd/MM/yyyy",
          new Date(),
        );

        const result = parseValues.cpfList.map((d) => {
          return {
            employeesAgeFrom: parseFloat(d.employeesAgeFrom),
            employeesAgeTo: parseFloat(d.employeesAgeTo),
            employerContribution: parseFloat(d.employerContribution),
            employeeContribution: parseFloat(d.employeeContribution),
            totalContribution: parseFloat(d.totalContribution),
          };
        });
        parseValues.cpfList = result;
        values.sdlRate = parseFloat(values?.sdlRate);
        if (params?.id !== "create") {
          await requestUpdateCpfb({ id: params?.id, ...parseValues });
        } else {
          delete parseValues._id;
          await requestCpfb(parseValues);
        }
        navigate("/cpf-configuration");
      } catch (error) {
        console.error("error", error);
      }
    },
  });
  const addCpf = () => {
    const temp = [...values.cpfList];
    const employeesAgeTo = parseFloat(temp[temp.length - 1]?.employeesAgeTo);
    temp.push({
      employeeContribution: 0,
      employeesAgeFrom: employeesAgeTo + 1,
      employeesAgeTo: employeesAgeTo + 1,
      employerContribution: 0,
      totalContribution: 0,
    });
    setFieldValue("cpfList", temp);
  };
  const removeCpf = (i = 0) => {
    const temp = [...values.cpfList];
    temp.splice(i, 1);
    setFieldValue("cpfList", temp);
  };

  useAlertApi(params?.id ? resultUpdateCpfb : resultCpfb);
  useEffect(() => {
    if (params?.id) {
      setFieldValue("_id", params?.id);
    }
  }, [params]);
  useEffect(() => {
    if (cpfb?.data) {
      resetForm();
      const date = {
        cpfStartDate: dayjs(cpfb?.data.cpfStartDate).format("DD/MM/YYYY"),
        cpfEndDate: dayjs(cpfb?.data.cpfEndDate).format("DD/MM/YYYY"),
      };
      setValues({
        ...cpfb?.data,
        ...date,
      });
    }
  }, [cpfb]);

  useEffect(() => {
    values?.cpfList?.forEach((d, i) => {
      if (d?.employerContribution && d?.employeeContribution) {
        setFieldValue(
          `cpfList.${i}.totalContribution`,
          parseFloat(d?.employerContribution || 0) +
            parseFloat(d?.employeeContribution || 0),
        );
      }
    });
  }, [values?.cpfList]);
  useEffect(() => {
    if (!cpfb?.data) {
      if (params?.id !== "create") {
        navigate(`/cpf-configuration`);
      }
    }
  }, [values?.cpfName, params?.id]);

  return (
    <div className="w-full space-y-4 mt-5">
      <div className="w-[100%]  flex justify-end">
        <Button
          style="solid"
          onClick={() => handleSubmit()}
          label={
            <div className="flex px-4 gap-1">
              <SaveIconSVG color={"white"} />
              <div>{params?.id !== "create" ? "Update" : "Save"}</div>
            </div>
          }
        />
      </div>
      <div className="w-full flex mt-5 ">
        <Accordion
          title={`CPF Contribution Details`}
          children={
            <div className="mt-5 grid grid-cols-2 gap-4">
              <InputText
                value={values.cpfName}
                error={errors.cpfName}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                name={"cpfName"}
                id={"cpfName"}
                title="CPF Contribution Name"
              />
              <InputText
                value={values.cpfWages}
                error={errors.cpfWages}
                setFieldValue={setFieldValue}
                onChange={handleChange}
                name={"cpfWages"}
                id={"cpfWages"}
                title="Month Wages"
              />
              <InputDate
                name={"cpfStartDate"}
                id={"cpfStartDate"}
                label="Start Date Period*"
                setFieldValue={setFieldValue}
                value={values.cpfStartDate}
                errors={errors.cpfStartDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputDate
                name={"cpfEndDate"}
                id={"cpfEndDate"}
                label="End Date Period*"
                errors={errors.cpfEndDate}
                setFieldValue={setFieldValue}
                value={values.cpfEndDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ToggleSwitch
                value={values?.cpfStatus}
                onChange={(e) => {
                  setFieldValue("cpfStatus", !values.cpfStatus);
                }}
                name="Active"
                label={values?.cpfStatus ? "Active" : "Disabled"}
              />
            </div>
          }
        />
      </div>
      <div>
        <Accordion
          title={`CPF Contribution Rates`}
          children={
            <div>
              <div>CPF Contribution Rates</div>
              <div className="overflow-auto">
                {values?.cpfList?.map((d, i) => {
                  return (
                    <div
                      key={i + "-cpf"}
                      className="mt-5 flex gap-4 items-center "
                    >
                      <InputText
                        value={d.employeesAgeFrom}
                        error={errors.employeesAgeFrom}
                        setFieldValue={setFieldValue}
                        onChange={(d) => {
                          let value = d?.target?.value;
                          value = cleanNumberInput(value);
                          setFieldValue(
                            `cpfList.${i}.employeesAgeFrom`,
                            value || "0",
                          );
                        }}
                        disabled={i > 0}
                        name={`cpfList.${i}.employeesAgeFrom`}
                        id={`cpfList.${i}.employeesAgeFrom`}
                        title="From (Age)"
                        className={"text-[10px]"}
                      />

                      <div className="border rounded-full p-4 h-[30px] w-[30px] flex items-center justify-center">
                        <div>
                          <ArrowRightSVG color={"#000"} />
                        </div>
                      </div>
                      {values.cpfMaxSelect &&
                      i === values?.cpfList?.length - 1 ? (
                        <InputText
                          value={"Max"}
                          disabled
                          title="To (Age)"
                          className={"text-[10px]"}
                        />
                      ) : (
                        <InputText
                          value={d.employeesAgeTo}
                          error={errors.employeesAgeTo}
                          setFieldValue={setFieldValue}
                          onChange={(d) => {
                            let value = d?.target?.value;
                            value = cleanNumberInput(value);
                            setFieldValue(
                              `cpfList.${i}.employeesAgeTo`,
                              parseFloat(value) || 0,
                            );
                            if (values?.cpfList?.length > 1) {
                              values?.cpfList?.forEach((d2, i2) => {
                                if (
                                  i2 > 0 &&
                                  i + i2 + 1 <= values?.cpfList?.length
                                ) {
                                  setFieldValue(
                                    `cpfList.${i + i2}.employeesAgeFrom`,
                                    parseFloat(value) + i2 - 1 || 0,
                                  );
                                  setFieldValue(
                                    `cpfList.${i + i2}.employeesAgeTo`,
                                    parseFloat(value) + i2 - 1 + 1 || 0,
                                  );
                                }
                              });
                            }
                          }}
                          name={`cpfList.${i}.employeesAgeTo`}
                          id={`cpfList.${i}.employeesAgeTo`}
                          title="To (Age)"
                          className={"text-[10px]"}
                        />
                      )}

                      <div className="border rounded-full p-4 h-[30px] w-[30px] flex items-center justify-center">
                        <div>
                          <EqualSVG color={"#000"} />
                        </div>
                      </div>
                      <InputText
                        value={d.employerContribution}
                        error={errors.employerContribution}
                        onChange={(d) => {
                          let value = d?.target?.value;
                          value = cleanNumberInput(value);
                          setFieldValue(
                            `cpfList.${i}.employerContribution`,
                            value || "0",
                          );
                        }}
                        name={`cpfList.${i}.employerContribution`}
                        id={`cpfList.${i}.employerContribution`}
                        title="By Employer (% wage)"
                        className={"text-[10px]"}
                      />
                      <InputText
                        value={d.employeeContribution}
                        error={errors.employeeContribution}
                        onChange={(d) => {
                          let value = d?.target?.value;
                          value = cleanNumberInput(value);
                          setFieldValue(
                            `cpfList.${i}.employeeContribution`,
                            value || "0",
                          );
                        }}
                        name={`cpfList.${i}.employeeContribution`}
                        id={`cpfList.${i}.employeeContribution`}
                        title="By Employee (% wage)"
                        className={"text-[10px]"}
                      />
                      <InputText
                        value={d.totalContribution}
                        error={errors.totalContribution}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        disabled={true}
                        name={`cpfList.${i}.totalContribution`}
                        id={`cpfList.${i}.totalContribution`}
                        title="Total (% wage)"
                        className={"text-[10px]"}
                      />
                      <div className="flex min-w-[200px] gap-4">
                        {i === values.cpfList.length - 1 && (
                          <div className="min-w-[110px]">
                            <CheckBox
                              onChange={() => {
                                setFieldValue(
                                  "cpfMaxSelect",
                                  !values.cpfMaxSelect,
                                );
                              }}
                              nameInput={`user.select.${i}`}
                              value={values.cpfMaxSelect}
                              label={"Set To Max"}
                            />
                          </div>
                        )}

                        {i > 0 && (
                          <button
                            onClick={() => removeCpf(i)}
                            className={"rounded-full w-[200px]"}
                          >
                            <TrashSvg color={"#000"} />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <br />
              <Button
                onClick={() => addCpf()}
                className={"w-full"}
                backgroundColor={"#DD7224"}
                label={
                  <div className="flex p-4 gap-1 capitalize text-white">
                    <UserAddIcon color={"#fff"} />
                    <div>add CPF Contribution Rates</div>
                  </div>
                }
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AddEditCpfb;
