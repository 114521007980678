import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Accordion,
  InputText,
  InputDate,
  ToggleSwitch,
  CheckBox,
} from "@bluesilodev/timhutcomponents";
import SaveIconSVG from "assets/icons/SaveIconSVG/SaveIconSVG";

import { useFormik } from "formik";
import { payrollShgSchema } from "type/typeCpf";
import { useSelector } from "react-redux";
import { useAlertApi } from "services/alert-api";
import { ArrowRightSVG } from "assets/icons/ArrowRightSVG";
import { EqualSVG } from "assets/icons/EqualSVG";
import TrashSvg from "assets/icons/TrashSvg/TrashSvg";
import UserAddIcon from "assets/icons/UserAddSVG/UserAdd";
import { cleanNumberInput } from "utils/numberUtils";
import { parse } from "date-fns";
import dayjs from "dayjs";
import { useAddShgMutation, useUpdateShgMutation } from "store/api/shgApi";
/**
 * Renders a component to manage and display settings for gross wages and minimum contributions.
 *
 * @param {Object} params - The parameters for the function.
 * @param {number} params.i - The index of the current item in the list.
 * @param {Object} params.d - The data object containing values for gross wages and minimum contributions.
 * @param {Object} params.errors - The errors object for form validation.
 * @param {Function} params.setFieldValue - The function to set form field values.
 * @param {Object} params.values - The current values of the form.
 * @param {string} [params.name="cdacSettings"] - The base name for the form field names.
 * @param {string} [params.nameSelect="shgMaxSelectMbmf"] - The name of the checkbox field to select maximum value.
 * @param {Function} params.removeShg - The function to remove an item from the list.
 *
 * @returns {JSX.Element} The rendered component.
 */
const ComListShg = ({
  i,
  d,
  errors,
  setFieldValue,
  values,
  name = "cdacSettings",
  nameSelect = "shgMaxSelectMbmf",
  removeShg,
}) => {
  return (
    <div key={i + `-${name}`} className="mt-5 flex gap-4 items-center ">
      <InputText
        value={d.grossWagesFrom}
        error={errors.grossWagesFrom}
        setFieldValue={setFieldValue}
        onChange={(d) => {
          let value = d?.target?.value;
          value = cleanNumberInput(value);
          setFieldValue(`${name}.${i}.grossWagesFrom`, value || "0");
        }}
        disabled={i > 0}
        name={`${name}.${i}.grossWagesFrom`}
        id={`${name}.${i}.grossWagesFrom`}
        title="From (Gross Wages)"
      />

      <div className="border rounded-full p-4 h-[30px] w-[30px] flex items-center justify-center">
        <div>
          <ArrowRightSVG color={"#000"} />
        </div>
      </div>
      {values[nameSelect] && i === values[name]?.length - 1 ? (
        <InputText value={"Max"} disabled title="To (Gross Wages)" />
      ) : (
        <InputText
          value={d.grossWagesTo}
          error={errors.grossWagesTo}
          setFieldValue={setFieldValue}
          onChange={(d) => {
            let value = d?.target?.value;
            value = cleanNumberInput(value);
            setFieldValue(`${name}.${i}.grossWagesTo`, value || "0");
          }}
          name={`${name}.${i}.grossWagesTo`}
          id={`${name}.${i}.grossWagesTo`}
          title="To (Gross Wages)"
        />
      )}

      <div className="border rounded-full p-4 h-[30px] w-[30px] flex items-center justify-center">
        <div>
          <EqualSVG color={"#000"} />
        </div>
      </div>
      <InputText
        value={d.minContribution}
        error={errors.minContribution}
        onChange={(d) => {
          let value = d?.target?.value;
          value = cleanNumberInput(value);
          setFieldValue(`${name}.${i}.minContribution`, value || "0");
        }}
        name={`${name}.${i}.minContribution`}
        id={`${name}.${i}.minContribution`}
        title="Min Monthly Contribution"
      />
      <div className="flex min-w-[200px] gap-4">
        {i === values[name].length - 1 && (
          <div className="min-w-[110px]">
            <CheckBox
              onChange={() => {
                setFieldValue(nameSelect, !values[nameSelect]);
              }}
              nameInput={`user.select.${i}.${nameSelect}`}
              value={values[nameSelect]}
              label={"Set To Max"}
            />
          </div>
        )}

        {i > 0 && (
          <button
            onClick={() => removeShg(i, name)}
            className={"rounded-full w-[200px]"}
          >
            <TrashSvg color={"#000"} />
          </button>
        )}
      </div>
    </div>
  );
};
const AddEditShg = () => {
  const params = useParams();
  /**
   * @typedef {Object} PayrollShgSchema
   * @property {import("type/typeCpf").PayrollShgSchema} data
   */
  /**
   * @type {PayrollShgSchema}
   */
  const shg = useSelector((state) => state.cpfbSlice);
  const [requestShg, resultShg] = useAddShgMutation();
  const [requestUpdateShg, resultUpdateShg] = useUpdateShgMutation();

  const navigate = useNavigate();

  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    errors,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues: {
      shgName: "",
      shgStartDate: "",
      shgEndDate: "",
      shgStatus: false,
      shgMaxSelectCdac: false,
      shgMaxSelectMbmf: false,
      shgMaxSelectEcf: false,
      shgMaxSelectSinda: false,
      cdacSettings: [
        { grossWagesFrom: 0, grossWagesTo: 1000, minContribution: 0 },
        { grossWagesFrom: 1000, grossWagesTo: 2000, minContribution: 0 },
      ],
      mbmfSettings: [
        { grossWagesFrom: 0, grossWagesTo: 1000, minContribution: 0 },
        { grossWagesFrom: 1000, grossWagesTo: 2000, minContribution: 0 },
      ],
      ecfSettings: [
        { grossWagesFrom: 0, grossWagesTo: 1000, minContribution: 0 },
        { grossWagesFrom: 1000, grossWagesTo: 2000, minContribution: 0 },
      ],
      sindaSettings: [
        { grossWagesFrom: 0, grossWagesTo: 1000, minContribution: 0 },
        { grossWagesFrom: 1000, grossWagesTo: 2000, minContribution: 0 },
      ],
    },
    validationSchema: payrollShgSchema,
    onSubmit: async (values) => {
      try {
        const parseValues = { ...values };

        if (
          parseValues?.__v !== "" &&
          parseValues?.__v !== undefined &&
          params?.id
        ) {
          delete parseValues.__v;
        }
        if (parseValues?.createdAt && params?.id) {
          delete parseValues.createdAt;
        }
        if (parseValues?.updatedAt && params?.id) {
          delete parseValues.updatedAt;
        }
        parseValues.shgStartDate = parse(
          parseValues?.shgStartDate,
          "dd/MM/yyyy",
          new Date(),
        );
        parseValues.shgEndDate = parse(
          parseValues?.shgEndDate,
          "dd/MM/yyyy",
          new Date(),
        );
        const settingsKeys = [
          "cdacSettings",
          "ecfSettings",
          "mbmfSettings",
          "sindaSettings",
        ];

        settingsKeys.forEach((key) => {
          parseValues[key] = parseValues[key].map((d) => ({
            grossWagesFrom: parseFloat(d.grossWagesFrom),
            grossWagesTo: parseFloat(d.grossWagesTo),
            minContribution: parseFloat(d.minContribution),
          }));
        });
        params?.id
          ? await requestUpdateShg({ id: params?.id, ...parseValues })
          : await requestShg(parseValues);
        navigate("/shg-funds");
      } catch (error) {
        console.error("error", error);
      }
    },
  });

  /**
   * @param {'cdacSettings' | 'sindaSettings' | 'mbmfSettings' | 'ecfSettings'} status
   */
  const addShg = (status) => {
    const tempObject = {
      grossWagesFrom: 0,
      grossWagesTo: 0,
      minContribution: 0,
    };

    switch (status) {
      case "sindaSettings":
        {
          const temp = [...values.sindaSettings];
          const plus = parseFloat(temp[temp.length - 1]?.grossWagesTo);
          temp.push({
            ...tempObject,
            grossWagesFrom: plus,
          });
          setFieldValue("sindaSettings", temp);
        }
        break;
      case "mbmfSettings":
        {
          const temp = [...values.mbmfSettings];
          const plus = parseFloat(temp[temp.length - 1]?.grossWagesTo);
          temp.push({
            ...tempObject,
            grossWagesFrom: plus,
          });
          setFieldValue("mbmfSettings", temp);
        }
        break;
      case "ecfSettings":
        {
          const temp = [...values.ecfSettings];
          const plus = parseFloat(temp[temp.length - 1]?.grossWagesTo);
          temp.push({
            ...tempObject,
            grossWagesFrom: plus,
          });
          setFieldValue("ecfSettings", temp);
        }
        break;
      case "cdacSettings":
        {
          const temp = [...values.cdacSettings];
          const plus = parseFloat(temp[temp.length - 1]?.grossWagesTo);
          temp.push({
            ...tempObject,
            grossWagesFrom: plus,
          });
          setFieldValue("cdacSettings", temp);
        }
        break;
      default:
        break;
    }
  };

  /**
   * @param {'cdacSettings' | 'sindaSettings' | 'mbmfSettings' | 'ecfSettings'} status
   * @param {number} i
   */
  const removeShg = (i = 0, status) => {
    switch (status) {
      case "sindaSettings":
        {
          const temp = [...values.sindaSettings];
          temp.splice(i, 1);
          setFieldValue("sindaSettings", temp);
        }
        break;
      case "mbmfSettings":
        {
          const temp = [...values.mbmfSettings];
          temp.splice(i, 1);
          setFieldValue("mbmfSettings", temp);
        }
        break;
      case "ecfSettings":
        {
          const temp = [...values.ecfSettings];
          temp.splice(i, 1);
          setFieldValue("ecfSettings", temp);
        }
        break;
      case "cdacSettings":
        {
          const temp = [...values.cdacSettings];
          temp.splice(i, 1);
          setFieldValue("cdacSettings", temp);
        }
        break;
      default:
        break;
    }
  };

  useAlertApi(params?.id ? resultUpdateShg : resultShg);
  useEffect(() => {
    if (params?.id) {
      setFieldValue("_id", params?.id);
    }
  }, [params]);
  useEffect(() => {
    if (shg?.dataShg) {
      const data = shg?.dataShg;
      // resetForm();
      const date = {
        shgStartDate: dayjs(data.shgStartDate).format("DD/MM/YYYY"),
        shgEndDate: dayjs(data.shgEndDate).format("DD/MM/YYYY"),
      };

      setValues({ ...data, ...date });
    }
  }, [shg?.dataShg]);

  return (
    <div className="w-full space-y-4 mt-5">
      <div className="w-[100%]  flex justify-end">
        <Button
          style="solid"
          onClick={() => handleSubmit()}
          label={
            <div className="flex px-4 gap-1">
              <SaveIconSVG color={"white"} />
              <div>{params?.id ? "Update" : "Save"}</div>
            </div>
          }
        />
      </div>
      <div className="w-full flex mt-5 ">
        <Accordion
          title={`SHG Funds Details`}
          children={
            <div className="mt-5 grid grid-cols-2 gap-4">
              <div className="col-span-full">
                <InputText
                  value={values.shgName}
                  error={errors.shgName}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  name={"shgName"}
                  id={"shgName"}
                  title="SHG Contribution Name"
                />
              </div>

              <InputDate
                name={"shgStartDate"}
                id={"shgStartDate"}
                label="Start Date Period*"
                setFieldValue={setFieldValue}
                value={values.shgStartDate}
                errors={errors.shgStartDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputDate
                name={"shgEndDate"}
                id={"shgEndDate"}
                label="End Date Period*"
                errors={errors.shgEndDate}
                setFieldValue={setFieldValue}
                value={values.shgEndDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ToggleSwitch
                value={values?.shgStatus}
                onChange={(e) => {
                  setFieldValue("shgStatus", !values.shgStatus);
                }}
                name="Active"
                label={values?.shgStatus ? "Active" : "Disabled"}
              />
            </div>
          }
        />
      </div>
      <div>
        <Accordion
          title={`Chinese Development Assistance (CDAC) Fund`}
          children={
            <div>
              <div>Chinese Development Assistance (CDAC) Fund</div>
              <div className="overflow-auto">
                {values?.cdacSettings?.map((d, i) => {
                  return (
                    <ComListShg
                      d={d}
                      errors={errors}
                      i={i}
                      removeShg={removeShg}
                      setFieldValue={setFieldValue}
                      values={values}
                      name={"cdacSettings"}
                      nameSelect="shgMaxSelectCdac"
                    />
                  );
                })}
              </div>

              <br />
              <Button
                className={"w-full"}
                onClick={() => addShg("cdacSettings")}
                backgroundColor={"#DD7224"}
                label={
                  <div className="flex p-4 gap-1 capitalize text-white">
                    <UserAddIcon color={"#fff"} />
                    <div>
                      <div>Add Chinese Development Assistance (CDAC) Fund</div>
                    </div>
                  </div>
                }
              />
            </div>
          }
        />
        <Accordion
          title={`Mosque Building and Mendaki Fund (MBMF)`}
          children={
            <div>
              <div>Mosque Building and Mendaki Fund (MBMF)</div>
              <div className="overflow-auto">
                {values?.mbmfSettings?.map((d, i) => {
                  return (
                    <ComListShg
                      d={d}
                      errors={errors}
                      i={i}
                      removeShg={removeShg}
                      setFieldValue={setFieldValue}
                      values={values}
                      name={"mbmfSettings"}
                      nameSelect="shgMaxSelectMbmf"
                    />
                  );
                })}
              </div>

              <br />
              <Button
                onClick={() => addShg("mbmfSettings")}
                className={"w-full"}
                backgroundColor={"#DD7224"}
                label={
                  <div className="flex p-4 gap-1 capitalize text-white">
                    <UserAddIcon color={"#fff"} />
                    <div>Add Mosque Building and Mendaki Fund</div>
                  </div>
                }
              />
            </div>
          }
        />
        <Accordion
          title={`Eurasian Community Fund (ECF)`}
          children={
            <div>
              <div>Eurasian Community Fund (ECF)</div>
              <div className="overflow-auto">
                {values?.ecfSettings?.map((d, i) => {
                  return (
                    <ComListShg
                      d={d}
                      errors={errors}
                      i={i}
                      removeShg={removeShg}
                      setFieldValue={setFieldValue}
                      values={values}
                      name={"ecfSettings"}
                      nameSelect="shgMaxSelectEcf"
                    />
                  );
                })}
              </div>

              <br />
              <Button
                onClick={() => addShg("ecfSettings")}
                className={"w-full"}
                backgroundColor={"#DD7224"}
                label={
                  <div className="flex p-4 gap-1 capitalize text-white">
                    <UserAddIcon color={"#fff"} />
                    <div>Add Eurasian Community Fund (ECF)</div>
                  </div>
                }
              />
            </div>
          }
        />
        <Accordion
          title={`Singapore Indian Development Association (SINDA) Fund`}
          children={
            <div>
              <div>Singapore Indian Development Association (SINDA) Fund</div>
              <div className="overflow-auto">
                {values?.sindaSettings?.map((d, i) => {
                  return (
                    <ComListShg
                      d={d}
                      errors={errors}
                      i={i}
                      removeShg={removeShg}
                      setFieldValue={setFieldValue}
                      values={values}
                      name={"sindaSettings"}
                      nameSelect="shgMaxSelectSinda"
                    />
                  );
                })}
              </div>

              <br />
              <Button
                onClick={() => addShg("sindaSettings")}
                className={"w-full"}
                backgroundColor={"#DD7224"}
                label={
                  <div className="flex p-4 gap-1 capitalize text-white">
                    <UserAddIcon color={"#fff"} />
                    <div>
                      Singapore Indian Development Association (SINDA) Fund
                    </div>
                  </div>
                }
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AddEditShg;
