import { PrintPdf } from "components/common/printPdf";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  useFetchPayrollOptionMutation,
  useGeneratePdfPayrollMutation,
} from "store/api/payrollApi";
import styled from "styled-components";
import { formatRupiah } from "utils/numberUtils";

const PayrollReportDetail = () => {
  const params = useParams();

  const [requestPayrollOption, resultPayrollOption] =
    useFetchPayrollOptionMutation();

  useEffect(() => {
    requestPayrollOption({ id: params?.id });
  }, [params?.id]);
  const data = useMemo(() => {
    return resultPayrollOption?.data?.data[0];
  }, [resultPayrollOption]);

  const total = useMemo(() => {
    return data?.data?.reduce((pre, v) => {
      pre += v?.total || 0;
      return pre;
    }, 0);
  }, [data?.data]);
  const text = `THIS IS A COMPUTER GENERATED PRINTOUT AND NO SIGNATURE IS REQUIRED

PLEASE NOTE THAT THE CONTENTS OF THIS STATEMENT SHOULD BE TREATED WITH ABSOLUTE CONFIDENTIALITY EXCEPT TO THE EXTENT YOU ARE REQUIRED TO MAKE DISCLOSURE FOR ANY TAX, LEGAL, OR REGULATORY PURPOSES. ANY BREACH OF THIS CONFIDENTIALITY OBLIGATION WILL BE DEALT WITH SERIOUSLY, WHICH MAY INVOLVE DISCIPLINARY ACTION BEING TAKEN.

This payslip is generated by Timhut
https://hr.timhut.studio`;
  const Table = styled.table`
    tr th {
      padding: 10px;
    }
    tr td {
      padding: 10px;
    }
  `;
  const ComTableBody = useCallback(() => {
    const dataTable = data?.data;
    return dataTable?.map((d, i) => {
      const basicSalary = d?.user?.userInformation?.paymentDetail?.basicSalary;
      const earning = d?.claimsReimbursements?.data.reduce((pre, v) => {
        pre += v?.price;
        return pre;
      }, 0);
      const deduction = d?.deductions?.data.reduce((pre, v) => {
        pre += v?.price;
        return pre;
      }, 0);
      return (
        <tr key={i + "-id-table"} className="border-b border-slate-200">
          <td>{d?.user?.firstName}</td>
          <td>{d?.user?.userInformation?.employeementDetail?.jobPosition}</td>
          <td>{formatRupiah(basicSalary)}</td>
          <td>{formatRupiah(earning)}</td>
          <td>{formatRupiah(deduction)}</td>
          <td>{formatRupiah(d?.total)}</td>
        </tr>
      );
    });
  }, [data?.data]);
  const [generatePdf] = useGeneratePdfPayrollMutation();

  const handleDownload = async () => {
    try {
      // Mengirim permintaan untuk menghasilkan PDF
      await generatePdf(data?._id);
    } catch (err) {
      console.error("Download error:", err);
    }
  };

  return (
    <div className="py-5">
      <PrintPdf downloadCallback={handleDownload} asd={"ssd"}>
        <div className="flex items-center justify-between">
          <img
            src="/images/favicon-timhut.png"
            className="object-contain w-[100px] "
            alt=""
          />
          <div className="text-red-500">CONFIDENTIALS</div>
        </div>
        <div className="flex items-center justify-between font-semibold capitalize mt-4">
          <p className="text-xl">timhut</p>
          <div>PAYROLL REPORT</div>
        </div>
        <div className="w-[300px]">
          150 Orchard Rd, #08-19, Singapore Phone Number : +62 21291232
        </div>
        <div className="mt-10 ">
          <div className="flex justify-between">
            <div className="grid grid-cols-[200px_1fr] gap-x-4">
              <div className="capitalize">payroll period</div>
              <div>
                : {dayjs(data?.startDate).format("D MMMM")} -{" "}
                {dayjs(data?.endDate).format("D MMMM YYYY")}
              </div>
              <div className="capitalize">locations</div>
              <div>: {data?.location?.name}</div>
              <div className="capitalize">payroll label</div>
              <div>: {data?.label}</div>
              <div className="capitalize">payroll schedule</div>
              <div>: {dayjs(data?.paymentDate).format("DD MMMM YYYY")}</div>
              <div className="capitalize">total employee</div>
              <div>: {data?.data?.length} employees</div>
            </div>
            <div className="border rounded-md p-4 w-[300px]">
              <div>Total Disbursements</div>
              <div className="text-xl font-bold">{formatRupiah(total)}</div>
            </div>
          </div>

          <Table className=" w-full mt-10 text-left">
            <thead>
              <tr className="bg-gray-300 capitalize">
                <th>employee</th>
                <th>job position</th>
                <th>basic salary</th>
                <th>earnings</th>
                <th>deduction</th>
                <th>total</th>
                <th>grand total</th>
              </tr>
            </thead>
            <tbody>
              <ComTableBody />
            </tbody>
            <tfoot>
              <tr className="bg-gray-300 capitalize">
                <th colSpan={6}>Total Disbursments</th>
                <th>{formatRupiah(total)}</th>
              </tr>
            </tfoot>
          </Table>
        </div>

        <div className="text-gray-400 mt-[100px] whitespace-pre-line">
          {text}
        </div>
      </PrintPdf>
    </div>
  );
};
export default PayrollReportDetail;
