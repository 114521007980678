import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import AppRouter from "routes";

import AlertMessage from "components/alertMessage";
import { setLoginReducer, setRoleReducer } from "./store/slice/userLoginSlice";
import { jwtDecode } from "jwt-decode";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem("accessToken");

    const user = jwtDecode(localStorageAccessToken);
    if (localStorageAccessToken) {
      dispatch(
        setLoginReducer({
          currentUser: user,
          token: localStorageAccessToken,
        })
      );
      // dispatch(setRoleReducer());
    }
  }, []);

  return (
    <>
      <AppRouter />
      <AlertMessage />
    </>
  );
};

export default App;
