import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Accordion,
  InputText,
  TextArea,
  ToggleSwitch,
  WidgetCard,
} from "@bluesilodev/timhutcomponents";
import SaveIconSVG from "assets/icons/SaveIconSVG/SaveIconSVG";
import { useAlertApi } from "services/alert-api";
import {
  useAddClaimsTypeMutation,
  useUpdateClaimsTypeMutation,
} from "store/api/claimsTypeApi";
import { v7 } from "uuid";

const AddEditClaimsType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [claimTypeData, setClaimTypeData] = useState(
    state
      ? {
          id: state?._id,
          title: state?.title,
          description: state?.description,
          isActive: state?.isActive,
          uId: state?.uId,
          isEdit: true,
        }
      : {
          title: "",
          description: "",
          isActive: true,
          uId: v7(),
          isEdit: false,
        },
  );

  const [requestClaimsType, resultClaimsType] = useAddClaimsTypeMutation();
  const [requestUpdateClaimsType, resultUpdateClaimsType] =
    useUpdateClaimsTypeMutation();
  const [isError, setError] = useState(false);

  useAlertApi(resultClaimsType);

  useAlertApi(resultUpdateClaimsType);

  const handleCreateClaimType = () => {
    if (claimTypeData.title == "") {
      setError(true);
    } else {
      if (claimTypeData?.isEdit == true) {
        requestUpdateClaimsType(claimTypeData).then((res) => {
          if (res.data.data) {
            navigate("/claims-type");
          }
        });
      } else {
        requestClaimsType(claimTypeData).then((res) => {
          if (res.data.data) {
            navigate("/claims-type");
          }
        });
      }
    }
  };

  return (
    <div className="w-full flex-col mt-5 ">
      <div className="w-[100%]  flex justify-end">
        <Button
          style="solid"
          onClick={() => handleCreateClaimType()}
          label={
            <div className="flex px-4 gap-1">
              <SaveIconSVG color={"white"} />
              <div>{claimTypeData?.isEdit == true ? "Update" : "Save"}</div>
            </div>
          }
        />
      </div>
      <div className="w-full flex mt-5 ">
        <Accordion
          title={"Claims Type"}
          children={
            <div className="mt-5 w-[60%]">
              <InputText
                onChange={(e) => {
                  setClaimTypeData((oldV) => ({
                    ...oldV,
                    title: e.target.value,
                  }));
                }}
                value={claimTypeData?.title}
                error={
                  isError &&
                  claimTypeData.title == "" &&
                  "Claims Type is Required"
                }
                title="Claims Type"
              />
              <div className="flex mt-6 "></div>
              <TextArea
                label="Type Description"
                rows={6}
                value={claimTypeData?.description}
                onChange={(e) => {
                  setClaimTypeData((oldV) => ({
                    ...oldV,
                    description: e.target.value,
                  }));
                }}
              />
              <div className="flex mt-6 "></div>
              <WidgetCard
                height="60px"
                onClick={() => {}}
                radius="lg"
                width="150px"
                className="bottom border-[1px] rounded-[10px] border-black"
              >
                <div className="flex flex-col items-center  justify-center">
                  <ToggleSwitch
                    value={claimTypeData?.isActive}
                    onChange={(e) => {
                      setClaimTypeData((oldV) => ({
                        ...oldV,
                        isActive: !claimTypeData?.isActive,
                      }));
                    }}
                    name="Active"
                    label={claimTypeData?.isActive ? "Active" : "Disabled"}
                  />
                </div>
              </WidgetCard>
              <div className="flex mt-6 "></div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AddEditClaimsType;
