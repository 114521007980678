import { ModalDialog } from "@bluesilodev/timhutcomponents";
import { AlertPayrollSVG } from "assets/icons/AlertPayrollSVG";
import { LeaveDataDetailTable } from "components/common/customTables";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFetchPayrollLeaveReportQuery } from "store/api/payrollApi";
import {
  calculateAge,
  calculatePercentage,
  findPartPercentage,
  formatRupiah,
} from "utils/numberUtils";
import { useFetchCpfbStatusQuery } from "store/api/cpfbApi";
import { ContextReportAndPayslip } from "utils/constants";
const Cpf = ({ getValues, isShow, setIsShow, employeeCpf }) => {
  const [employee, setEmployee] = useState("");
  const [salary, setSalary] = useState(0);
  const salaryEmployer = useMemo(() => {
    if (!employeeCpf) {
      return;
    }
    let salary2 = getValues?.user.userInformation?.paymentDetail?.basicSalary;
    const salaryEmployer = calculatePercentage(
      salary2,
      employeeCpf?.employerContribution,
    );
    salary2 = calculatePercentage(salary2, employeeCpf?.employeeContribution);
    setEmployee(employeeCpf);
    setSalary(salary2);
    return salaryEmployer;
  }, [employeeCpf, getValues]);
  return (
    <ModalDialog
      show={isShow}
      className={"min-w-[1000px] w-[90vw]"}
      onClose={() => setIsShow(false)}
      title={"CPF Contributions"}
      children={
        <div className="grid grid-cols-2 gap-4">
          <div className="capitalize font-bold text-md col-span-full">
            CPF employee
          </div>
          <div className="border-2 border-gray-400 p-4 rounded-md space-y-2 text-md">
            <div>Rate</div>
            <div className="font-semibold">
              {employee?.employeeContribution} %
            </div>
          </div>
          <div className="border-2 border-gray-400 p-4 rounded-md space-y-2">
            <div>amount</div>
            <div className="font-semibold">{formatRupiah(salary)}</div>
          </div>
          <div className="capitalize font-bold text-md col-span-full">
            CPF employer
          </div>
          <div className="border-2 border-gray-400 p-4 rounded-md space-y-2">
            <div>Rate</div>
            <div className="font-semibold">
              {employee?.employerContribution} %
            </div>
          </div>
          <div className="border-2 border-gray-400 p-4 rounded-md space-y-2">
            <div>amount</div>
            <div className="font-semibold">{formatRupiah(salaryEmployer)}</div>
          </div>
        </div>
      }
    />
  );
};
export const Deduction = ({ getValues, dataDeduction }) => {
  const [isShow, setIsShow] = useState(false);
  const [isShow2, setIsShow2] = useState(false);
  const { data: dataCpf } = useFetchCpfbStatusQuery();

  const modalSelect = (d) => {
    switch (d?.status) {
      case "unpaid-leave":
        setIsShow(!isShow);
        break;
      case "cpf-contributions":
        setIsShow2(!isShow2);
        break;
      default:
        break;
    }
  };
  const basicSalary =
    getValues?.user?.userInformation?.paymentDetail?.basicSalary;

  const employeeCpf = useMemo(() => {
    if (dataCpf === null) {
      return;
    }
    if (dataCpf === undefined) {
      return;
    }
    if (dataCpf?.length === 0) {
      return;
    }
    const dateOfBirth = getValues?.user?.dateOfBirth;
    const result = dataCpf[0]?.cpfList.find(
      (d) =>
        calculateAge(dateOfBirth) >= d?.employeesAgeFrom &&
        calculateAge(dateOfBirth) <= d?.employeesAgeTo,
    );
    return result;
  }, [dataCpf, getValues]);
  const isStatusAllowed = (status) => {
    const disallowedStatuses = [
      "earning",
      "deduction",
      "deduction-percent",
      "earning-percent",
      "salary-ceilings",
      "fwl",
      "shg-fund",
      "sdl",
      "total-deductions",
    ];

    return !disallowedStatuses.includes(status);
  };
  const isDisallowedStatusWithZeroPrice = (data) => {
    const disallowedStatuses = ["fwl", "sdl", "shg-fund"];
    return disallowedStatuses.includes(data?.status) && data?.price === 0;
  };
  return (
    <>
      {dataDeduction?.deductions?.data?.map((d, i) => {
        if (isDisallowedStatusWithZeroPrice(d)) {
          return "";
        }
        return (
          <div key={i + "-id"}>
            <div
              className={`${
                d.status === "total-deductions" && "font-bold"
              } flex gap-2 items-center`}
            >
              <div className="flex gap-1">
                <div>{formatRupiah(d?.price)}</div>
                <div>
                  {d?.status === "deduction-percent" &&
                    `(${findPartPercentage(d?.price, basicSalary)} %) `}
                </div>
              </div>
              {isStatusAllowed(d?.status) && (
                <span
                  onClick={() => {
                    modalSelect(d);
                  }}
                >
                  <AlertPayrollSVG color={"#000"} />
                </span>
              )}
            </div>

            <div className="text-[12px] text-slate-500 capitalize">
              <div>{d?.label}</div>
              {d?.status === "unpaid-leave" && (
                <ModalDialog
                  show={isShow}
                  className={"min-w-[1000px] w-[90vw] h-auto"}
                  onClose={() => setIsShow(false)}
                  title={"Unpaid Leave"}
                  children={
                    <LeaveDataDetailTable
                      dataTable={dataDeduction?.unpaidLeave}
                    />
                  }
                />
              )}
              {d?.status === "cpf-contributions" && (
                <Cpf
                  isShow={isShow2}
                  employeeCpf={employeeCpf}
                  setIsShow={setIsShow2}
                  getValues={getValues}
                />
              )}
            </div>
          </div>
        );
      })}
      <div>
        <div className={`capitalize`}>
          <div className="font-bold">
            {formatRupiah(dataDeduction?.deductions?.totalDeductions)}
          </div>
          <div>total Deductions</div>
        </div>
      </div>
    </>
  );
};
export const DeductionDetail = ({ userID = "", data, getValues }) => {
  const [isShow, setIsShow] = useState(false);
  const [isShow2, setIsShow2] = useState(false);
  const { detail } = useContext(ContextReportAndPayslip);
  const { data: dataCpf } = useFetchCpfbStatusQuery();

  const employeeCpf = useMemo(() => {
    if (dataCpf === null) {
      return;
    }
    if (dataCpf === undefined) {
      return;
    }
    if (dataCpf?.length === 0) {
      return;
    }
    const dateOfBirth = getValues?.dateOfBirth;
    const result = dataCpf[0]?.cpfList.find(
      (d) =>
        calculateAge(dateOfBirth) >= d?.employeesAgeFrom &&
        calculateAge(dateOfBirth) <= d?.employeesAgeTo,
    );
    return result;
  }, [dataCpf, getValues]);
  const modalSelect = (d) => {
    switch (d?.status) {
      case "unpaid-leave":
        setIsShow(!isShow);
        break;
      case "cpf-contributions":
        setIsShow2(!isShow2);
        break;
      default:
        break;
    }
  };
  let { data: dataReportLeave } = useFetchPayrollLeaveReportQuery({
    startDate: detail?.startDate,
    endDate: detail?.endDate,
    searchDateEnable: true,
    approval: "approved",
    userID,
  });
  dataReportLeave = dataReportLeave?.data?.data;

  return (
    <>
      {data?.map((d, i) => {
        return (
          <div key={i + "-id"}>
            <div
              className={`flex gap-2 ${
                d.status === "total-deductions" && "font-bold"
              }`}
            >
              {formatRupiah(d?.price)}
              {d?.status !== "earning" &&
                d?.status !== "deduction" &&
                d?.status !== "deduction-percent" &&
                d?.status !== "earning-percent" &&
                d?.status !== "total-deductions" && (
                  <span
                    onClick={() => {
                      modalSelect(d);
                    }}
                  >
                    <AlertPayrollSVG color={"#000"} />
                  </span>
                )}
            </div>
            <div className="text-[12px]  text-slate-500 capitalize">
              <div>{d?.label}</div>
              {d?.status === "unpaid-leave" && (
                <ModalDialog
                  show={isShow}
                  className={"min-w-[1000px] w-[90vw] h-[90vh]"}
                  onClose={() => setIsShow(false)}
                  title={"Unpaid Leave"}
                  children={
                    <LeaveDataDetailTable dataTable={dataReportLeave || []} />
                  }
                />
              )}
              {d?.status === "cpf-contributions" && (
                <Cpf
                  isShow={isShow2}
                  employeeCpf={employeeCpf}
                  setIsShow={setIsShow2}
                  getValues={getValues}
                />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
