const { differenceInBusinessDays, parseISO } = require("date-fns");
/**
 * Calculate the amount earned for a given number of hours based on monthly salary
 * @param {number} monthlySalary - The monthly salary
 * @param {number} hoursWorked - The number of hours to calculate earnings for (e.g., 1.5 hours)
 * @param {number} [totalHoursInMonth=160] - The total work hours in the month (default is 160 hours)
 * @returns {number} - The amount earned for the given hours worked
 */
export function calculateEarningsForHours(
  monthlySalary,
  hoursWorked,
  totalHoursInMonth = 160,
) {
  // Calculate the hourly rate
  const hourlyRate = monthlySalary / totalHoursInMonth;

  // Calculate the earnings for the given hours worked
  return hourlyRate * hoursWorked;
}

export function calculateSalaryWithDateRange(
  monthlySalary,
  startDate,
  endDate,
  hoursPerDay,
) {
  // Convert date strings to Date objects
  const start = parseISO(startDate);
  const end = parseISO(endDate);

  // Calculate the number of business days (Monday to Friday) within the range
  const workingDays = differenceInBusinessDays(end, start);

  // Calculate daily salary
  const dailySalary = monthlySalary / workingDays;

  // Calculate hourly salary
  const hourlySalary = dailySalary / hoursPerDay;

  return {
    dailySalary: dailySalary,
    hourlySalary: hourlySalary,
    workingDays: workingDays,
  };
}
/**
 * Convert hours to days.
 * 24 hours = 1 day, 12 hours = 0.5 day
 *
 * @param {number} hours - The number of hours to convert.
 * @returns {number} - The equivalent number of days.
 */
export function convertHoursToDays(hours) {
  // 24 hours = 1 day, so divide hours by 24 to get the days.
  return hours / 24;
}
/**
 * Calculates the percentage of a value relative to the total.
 *
 * @param {number} value - The value to calculate the percentage for.
 * @param {number} total - The total value used as the base for the percentage calculation.
 * @returns {number} - The percentage of the value relative to the total.
 */
export function calculatePercentage(value, total) {
  if (total === 0) {
    throw new Error("Total cannot be zero");
  }
  return (value / total) * 100;
}
/**
 * Converts a percentage value to a decimal value.
 *
 * @param {number} percent - The percentage value to be converted.
 * @returns {number} - The decimal value equivalent to the percentage.
 *
 * @example
 * const decimalValue = percentToDecimal(0.25);
 * console.log(decimalValue); // Output: 0.0025
 */
export function percentToDecimal(percent) {
  return percent / 100;
}
/**
 * Converts a decimal value to a percentage value.
 *
 * @param {number} decimal - The decimal value to be converted.
 * @returns {number} - The percentage value equivalent to the decimal.
 *
 * @example
 * const percentValue = decimalToPercent(0.0025);
 * console.log(percentValue); // Output: 0.25
 */
export function decimalToPercent(decimal) {
  return decimal * 100;
}
