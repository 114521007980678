import React, { useEffect, useState } from "react";
import { Button, Accordion, InputNumber } from "@bluesilodev/timhutcomponents";
import SaveIconSVG from "assets/icons/SaveIconSVG/SaveIconSVG";
import {
  useAddPaymentScheduleMutation,
  useFetchPaymentScheduleBiUserIdQuery,
} from "store/api/parymentScheduleApi";
import { useAlertApi } from "services/alert-api";
import { useSelector } from "react-redux";
const PaymentSchedule = () => {
  const [noDay, setNoDay] = useState(30);
  const { currentUser, currentRole } = useSelector((state) => state.loginSlice);

  const [role] = React.useState(localStorage.getItem("role") || "user");
  const { data } = useFetchPaymentScheduleBiUserIdQuery(
    {
      userId: currentUser?.uId,
    },
    { refetchOnMountOrArgChange: true },
  );
  const [requestAddPaymentSchedule, { data: dataPayment }] =
    useAddPaymentScheduleMutation();
  const dataPayrollSchedule = data?.data;
  useAlertApi({ data: { dataPayment, message: "success" } });
  useEffect(() => {
    if (dataPayrollSchedule) {
      setNoDay(parseInt(dataPayrollSchedule?.time));
    }
  }, [dataPayrollSchedule]);
  function save() {
    requestAddPaymentSchedule({ time: noDay, userId: currentUser?.uId });
  }
  return (
    <div className="w-full flex-col mt-5 ">
      <div className="w-[100%]  flex justify-end">
        {currentRole?.toLowerCase() === "admin" && (
          <Button
            onClick={save}
            style="solid"
            label={
              <div className="flex px-4 gap-1">
                <SaveIconSVG color={"white"} />
                <div>Save</div>
              </div>
            }
          />
        )}
      </div>
      <div className="w-full flex mt-5 ">
        <Accordion
          title={"Payment Schedule"}
          children={
            <div className="mt-5 w-[30%]">
              <InputNumber
                disable={role?.toLowerCase() === "employee"}
                label="Payroll will be scheduled on this date every month"
                name="input_number1_1"
                value={noDay}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default PaymentSchedule;
