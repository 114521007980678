import { ModalDialog } from "@bluesilodev/timhutcomponents";
import { AlertPayrollSVG } from "assets/icons/AlertPayrollSVG";
import { ReviewEmployeeDataDetailTable } from "components/common/customTables";
import { useContext, useState } from "react";
import { useFetchPayrollCalculateLeaveOvertimeQuery } from "store/api/payrollApi";
import { ContextReportAndPayslip } from "utils/constants";

export const ReviewModal = ({ dataUser }) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      {dataUser?.approvalOOT?.totalTime}
      hours
      <span onClick={() => setIsShow(!isShow)}>
        <AlertPayrollSVG color={"#000"} />
      </span>
      <ModalDialog
        show={isShow}
        className={"min-w-[1000px] w-[90vw] h-[90vh]"}
        onClose={() => setIsShow(false)}
        title={"Approved Overtime"}
        children={
          <ReviewEmployeeDataDetailTable
            dataTable={dataUser?.approvalOOT?.data ?? []}
          />
        }
      />
    </>
  );
};
export const ReviewModalDetail = ({ total, userID, getValues }) => {
  const [isShow, setIsShow] = useState(false);
  const { detail } = useContext(ContextReportAndPayslip);
  const { data } = useFetchPayrollCalculateLeaveOvertimeQuery({
    startDate: detail?.startDate,
    endDate: detail?.endDate,
    searchDateEnable: true,
    approval: "approved",
    userID,
  });
  const dataTable = data?.data?.data;

  return (
    <>
      {total} hours
      <span onClick={() => setIsShow(!isShow)}>
        <AlertPayrollSVG color={"#000"} />
      </span>
      <ModalDialog
        show={isShow}
        className={"min-w-[1000px] w-[90vw] h-[90vh]"}
        onClose={() => setIsShow(false)}
        title={"Approved Overtime"}
        children={<ReviewEmployeeDataDetailTable dataTable={dataTable || []} />}
      />
    </>
  );
};
