import React from "react";

export const PresentationSvg = ({ color, ...otherProps }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5 5C3.94772 5 3.5 5.44772 3.5 6V18C3.5 18.5523 3.94772 19 4.5 19H20.5C21.0523 19 21.5 18.5523 21.5 18V6C21.5 5.44772 21.0523 5 20.5 5H4.5ZM1.5 6C1.5 4.34315 2.84315 3 4.5 3H20.5C22.1569 3 23.5 4.34315 23.5 6V18C23.5 19.6569 22.1569 21 20.5 21H4.5C2.84315 21 1.5 19.6569 1.5 18V6Z"
        fill={color}
      />
      <path
        d="M9.89615 8.36625C9.37496 9.02145 9.84154 9.98877 10.6788 9.98877H14.3214C15.1586 9.98877 15.6252 9.02145 15.104 8.36625L13.2827 6.07659C12.8823 5.57329 12.1178 5.57329 11.7175 6.07659L9.89615 8.36625Z"
        fill={color}
      />
      <path
        d="M15.1038 15.7153C15.625 15.0601 15.1585 14.0928 14.3212 14.0928H10.6786C9.8414 14.0928 9.37483 15.0601 9.89602 15.7153L11.7173 18.005C12.1177 18.5083 12.8822 18.5083 13.2825 18.005L15.1038 15.7153Z"
        fill={color}
      />
    </svg>
  );
};
