import { DotThreeSVG } from "assets/icons/DotThreeSVG";
import { MenuPayrollSVG } from "assets/icons/MenuPayrollSVG";
import { MinusPayrollSVG } from "assets/icons/MinusPayrollSVG";
import { PlusPayrollSVG } from "assets/icons/PlusPayrollSVG";
import { PresentationSvg } from "assets/icons/PresentationSVG";
import { RotatePayrollSVG } from "assets/icons/RotatePayrollSVG";
import { RoundDownloadSVG } from "assets/icons/RoundDownloadSVG";
import { RoundPrintSVG } from "assets/icons/RoundPrintSVG";
import { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { handleZoom } from "utils/numberUtils";
const DownloadPDFButton = () => {
  const { downloadCallback } = useContext(ContextPrintPdf);

  return (
    <button onClick={downloadCallback}>
      <RoundDownloadSVG color={"#fff"} />
    </button>
  );
};
const ZoomableComponent = ({ children }) => {
  const { zoomLevel } = useContext(ContextPrintPdf);

  return (
    <div
      className="zoomable-content w-full p-10"
      style={{
        transform: `scale(${zoomLevel})`,
        transformOrigin: "top left right",
        transition: "transform 0.3s ease-in-out",
      }}
    >
      <div className="bg-white w-full  p-4 flex rounded-md ">
        <div className="w-full  m-[100px]">{children}</div>
      </div>
    </div>
  );
};

const PercentageInput = () => {
  const { setZoomLevel, zoomLevel } = useContext(ContextPrintPdf);

  // State to store the value as a number
  const [zoomPercentage, setZoomPercentage] = useState(100);

  // Handler for input changes
  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Remove the % symbol before processing
    const numericValue = inputValue.replace("%", "");

    // Ensure only numbers are allowed
    if (!isNaN(numericValue) && numericValue >= 0) {
      setZoomPercentage(numericValue);
      setZoomLevel(zoomLevel);
    }
  };

  return (
    <div className="flex gap-3">
      <button
        onClick={() =>
          handleZoom("out", zoomLevel, setZoomLevel, setZoomPercentage)
        }
      >
        <MinusPayrollSVG color={"#fff"} />
      </button>
      <input
        type="text"
        value={zoomPercentage !== "" ? `${zoomPercentage}%` : ""}
        onChange={handleChange}
        disabled
        placeholder="0%"
        className="bg-black p-1 focus:outline-none w-[50px] text-center"
      />
      <button
        onClick={() =>
          handleZoom("in", zoomLevel, setZoomLevel, setZoomPercentage)
        }
      >
        <PlusPayrollSVG color={"#fff"} />
      </button>
    </div>
  );
};
const ContextPrintPdf = createContext({
  zoomLevel: 0,
  setZoomLevel: (data) => data,
  downloadCallback: (data) => data,
});

const PrintPdf = ({ downloadCallback, children }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  return (
    <ContextPrintPdf.Provider
      value={{ zoomLevel, setZoomLevel, downloadCallback }}
    >
      <div className="image overflow-y-auto max-h-[600px] h-[600px] w-full bg-primaryBgPrint  rounded-md">
        <div className="sticky top-0 grid grid-cols-3 bg-primaryPrint text-white p-4 z-50">
          <div className="flex gap-4">
            <MenuPayrollSVG color={"#ffff"} />
            <p>File name</p>
          </div>
          <div className="flex justify-center gap-4">
            <div className="flex gap-4 items-center">
              <div>1</div>
              <div>/</div>
              <div>1</div>
            </div>
            <div className="h-full w-[1px] bg-white"></div>
            <PercentageInput />
            <div className="h-full w-[1px] bg-white"></div>
            <div className="flex gap-4 items-center">
              <div>
                <PresentationSvg color={"#fff"} />
              </div>
              <div>
                <RotatePayrollSVG color={"#fff"} />
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-end items-center ">
            <DownloadPDFButton />
            <RoundPrintSVG color={"#fff"} />
            <DotThreeSVG color={"#ffff"} />
          </div>
        </div>
        <ZoomableComponent children={children} />
      </div>
    </ContextPrintPdf.Provider>
  );
};

export { PrintPdf };
