import React, { useContext, useEffect, useMemo, useState } from "react";
import { EmployeeDataTable } from "../common/customTables";
import {
  Accordion,
  InputDate,
  InputSelect,
  InputText,
  RadioButton,
} from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import {
  useFetchEmployeeComponentQuery,
  useFetchEmployeeLocationQuery,
} from "store/api/employeeComponentApi";
import { PayrollRunSetting } from "type/typePayroll";
import Steps from "./steps";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataLocationEmployeeReducers,
  setPayrollReducers,
} from "store/slice/payrollSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContextSteps } from "pages/runPayroll";
import { useFetchPayrollOptionMutation } from "store/api/payrollApi";
import dayjs from "dayjs";

const Setting = ({ steps, labelSteps }) => {
  const dispatch = useDispatch();
  const { setSteps } = useContext(ContextSteps);
  const { payrollSlice, loginSlice } = useSelector((state) => state);
  const navigate = useNavigate();
  const [requestPayrollOptional] = useFetchPayrollOptionMutation();
  const { id } = useParams();
  const location = useLocation();
  const [locationName, setLocationName] = useState({ label: "", value: "" });
  const [statusEmployee] = useState([
    {
      id: "option1",
      label: "All Employee in this Location",
    },
    {
      id: "option2",
      label: "Selected Employee only (10 selected)",
    },
  ]);

  const {
    handleBlur,
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    setValues,
    errors,
  } = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      location: "",
      employmentStatus: "option2",
      label: "",
      selectedEmployees: [],
      paymentDate: "",
    },
    validationSchema: PayrollRunSetting,
    onSubmit: (values) => {
      dispatch(setPayrollReducers(values));
      setSteps((prev) => prev + 1);
      if (id) {
        navigate(`/run-payroll/${id}?status=review`);
      } else {
        navigate("/run-payroll?status=review");
      }
    },
  });
  // Get data employee
  const { data } = useFetchEmployeeComponentQuery(
    {
      organizationID: loginSlice?.currentUser?.organizationID,
      location: locationName?.label,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  // Get data location
  const { data: data2 } = useFetchEmployeeLocationQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const selectStatusEmployee = (employee) => {
    if (employee) {
      const find = statusEmployee.find((d) => d.label === employee);
      if (find?.id === "option1") {
        setFieldValue("selectedEmployees", data?.data);
      } else {
        setFieldValue("selectedEmployees", null);
      }
      setFieldValue("employmentStatus", find.id);
    }
  };
  const dataLocationEmployee = useMemo(() => {
    const result = data2?.data?.map((d) => {
      return { label: d?.locationName, value: d?.uId };
    });
    dispatch(setDataLocationEmployeeReducers(result));
    return result;
  }, [data2?.data]);

  useEffect(() => {
    if (payrollSlice?.payrollSetting?.payload?.startDate) {
      setValues(payrollSlice?.payrollSetting?.payload);
    }
  }, [payrollSlice?.payrollSetting?.payload]);
  useEffect(() => {
    if (id) {
      requestPayrollOptional({ id }).then((r) => {
        const data = r?.data?.data[0];
        setValues({
          startDate: dayjs(data?.paymentDate).format("DD/MM/YYYY"),
          endDate: dayjs(data?.endDate).format("DD/MM/YYYY"),
          paymentDate: dayjs(data?.paymentDate).format("DD/MM/YYYY"),
          label: data?.label,
          location: data?.location?.id,
          selectedEmployees: data?.data?.map((d) => d?.user),
          employmentStatus: data?.employmentStatus,
        });
      });
    } else {
      dispatch(setPayrollReducers(null));
    }
  }, [id, requestPayrollOptional, setValues, location]);
  useEffect(() => {
    const find = dataLocationEmployee?.find(
      (d) => d?.value === values.location,
    );
    setLocationName(find);
  }, [values.location, dataLocationEmployee]);
  return (
    <form onSubmit={handleSubmit}>
      <Steps labelSteps={labelSteps} steps={steps} setSteps={setSteps} />
      <div>
        <div className="w-full flex mt-5 ">
          <Accordion
            title={<div className="p-2">Payroll Setup</div>}
            icons={[]}
            children={
              <div className="p-4 w-full grid grid-cols-[2fr_1fr] gap-5">
                <div className="grid grid-cols-2 p-1 gap-4 w-full">
                  <div className={"w-full"}>
                    <InputText
                      value={values.label}
                      onChange={handleChange}
                      name={"label"}
                      id={"label"}
                      title={"Payroll Label *"}
                      className="w-full flex items-center gap-2"
                    />
                    {errors.label && (
                      <div className="text-red-400 mt-4 capitalize">
                        {errors.label}
                      </div>
                    )}
                  </div>

                  <div className="w-full">
                    <InputDate
                      name={"paymentDate"}
                      id={"paymentDate"}
                      label="Payment Schedule *"
                      setFieldValue={setFieldValue}
                      value={values.paymentDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.paymentDate && (
                      <div className="text-red-400 mt-4 capitalize">
                        {errors.paymentDate}
                      </div>
                    )}
                  </div>

                  <div className={"w-full"}>
                    <InputDate
                      id={"startDate"}
                      name="startDate"
                      label="Start Date Period *"
                      setFieldValue={setFieldValue}
                      value={values.startDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.startDate && (
                      <div className="text-red-400 mt-4 capitalize">
                        {errors.startDate}
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <InputDate
                      id={"endDate"}
                      name="endDate"
                      label="End Date Period *"
                      setFieldValue={setFieldValue}
                      value={values.endDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                    />
                    {errors.endDate && (
                      <div className="text-red-400 mt-4 capitalize">
                        {errors.endDate}
                      </div>
                    )}
                  </div>

                  <div className={"w-full mt-3"}>
                    <InputSelect
                      title={"Location *"}
                      id={"location"}
                      name="location"
                      setFieldValue={setFieldValue}
                      value={values.location}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={dataLocationEmployee}
                    />
                    {errors.location && (
                      <div className="text-red-400 mt-4 capitalize">
                        {errors.location}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div className="w-full flex mt-5 ">
          <Accordion
            title={<div className="p-2">Employee</div>}
            icons={[]}
            children={
              <div className="p-4 w-full flex flex-col">
                <div className="flex flex-row p-1 gap-2 w-full">
                  <RadioButton
                    name="radio_button"
                    onClick={(d) => selectStatusEmployee(d.target.value)}
                    value={values.employmentStatus}
                    options={statusEmployee}
                  />
                </div>
                {errors.selectedEmployees && (
                  <div className="text-red-400 mt-4 capitalize">
                    {errors.selectedEmployees}
                  </div>
                )}
                {values.employmentStatus === "option2" && (
                  <div className="grid grid-cols-[2fr_1fr] mt-2 p-1 gap-2 ">
                    <EmployeeDataTable
                      setFieldValue={setFieldValue}
                      values={values.selectedEmployees}
                      dataTable={data?.data.length > 0 ? data?.data : []}
                    />
                  </div>
                )}
              </div>
            }
          />
        </div>
      </div>
    </form>
  );
};
export default Setting;
